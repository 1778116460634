<script>
import appConfig from "@/app.config";
import timeSlot from "@/mixins/timeSlot";
import Swal from "sweetalert2";
/**
 * Add-product component
 */
export default {
  mixins:[timeSlot],
  page: {
    title: "Shifts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
   
  },
  data() {
    return {
      processing:false,
      selectedShifts:[],
      selected_shift:null,
      shiftTimeSlots:[],
      shift_id:null,
      shifts:[],
      area_role_id:null,
      counts:null,
      shiftSelectedTimeId:null,
      pickShiftModal:false,
      // shift:null,
      table_data : {
        draw: 0,
        size: 10,
        status : 'New_Shifts'
      },
      perPage: 10,
      currentPage: 1,
      fields: [
        {
            key: "name",
            label: "Shift Name",
            sortable: true,
        },
            {
            label: "Shift From",
            key: "shift_time_slots[0].from",
            formatter:(key) => {
                return this.DateWithMonthName(key)
            },
            sortable: true,
        },
            {
            label: "Shift To",
            key: "shift_time_slots[0].to",
            formatter:(key) => {
                return this.DateWithMonthName(key)
            },
            sortable: true,
        },
      
        {
            key: "interval",
            label: "Interval",
            sortable: true,
        },
        {
            key: "location.name",
            label: "Location",
            sortable: true,
        },
        {
            key: "shift_type",
            label: "Shift Type",
            sortable: true,
        },
        {
            key: "created_at",
            label: "Created At",
            sortable: true,
            formatter:(key) => {
              return this.DateWithMonthName(key , 'ddd, MMM DD,YYYY hh:mm A')
            },
        },
        "action",
      ],
      total_records: {},
      loading: false,
    };
  },
  middleware: "authentication",
   mounted(){
    // this.fetchSelectedShift();
    this.fetchShift();
    
  },
   watch:{
     currentPage:{
         handler:function(currentPage,pre_currentPage){
             if(currentPage>pre_currentPage){
                 this.table_data.size+=this.perPage
                 this.fetchShift();
             }
         }
     },
   },
  methods:{
    fetchSelectedShift() {
        this.$axios.get("shift/employee/selected-shifts", 
        {params:{'area_role_id' : this.area_role_id, 'shift_id' : this.selected_shift.id ,
         employee_id : this.currentUser.employee.id}}
        ).then((response) => {
        let data = response.data.payload;
        this.selectedShifts = data;
        //show the seleted shift timing and role.
        // this.getRoleName(this.selected_shift);
        // this.fetchShift();
        }).catch(error => {
        console.error(error);
        this.handleErrorResponse(error.response, "error");
      }).finally(function(){

      });     
    },
    fetchShift(url) {
      url = url ?? "shift/employee/shift-by-role-paginate/"+this.currentUser.employee.id;
      this.loading = true;
      this.$axios.get(url,
      {params: this.table_data}).then((response) => {
      let data = response.data.payload.data;
      this.total_records = response.data.payload;
      this.counts=data['counts'];
      this.shifts=data['shifts'];
      this.loading = false 
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      }).finally(() => (this.loading = false));
    },
           
    submitShift(e) {
      this.submit = true;

      let self = this;
      if (self.processing) {
          return;
      }
      self.processing = true;
      
      self.$axios.post('shift/employee/select-shift', {
          employee_id:this.currentUser.employee.id,
          shift_id:this.shift_id, 
          area_role_id:this.area_role_id,
          slot_id:this.shiftSelectedTimeId,
          status:'Pending'
      })
      .then(response => {
          let data = response.data;
          self.triggerSwal(data.message, "success");
          this.shifts = this.shifts.filter(shift => (shift.id != this.selected_shift.id));
          this.fetchSelectedShift();
          this.$emit('refresh');
      })
      .catch(error => {
          self.handleErrorResponse(error.response, "error");
          
      })
      .finally(() =>{
          self.processing = false;
          self.selected_shift = null;
      });
    },

    selectShifts(shift) {
      this.area_role_id=null;

      this.selected_shift={...shift};

      if(shift.status != 'Rejected' && shift.status != 'Released' && shift.status != 'Apply For Leave'  ){
        this.shift_id = shift.id;
        this.pickShiftModal=true;
      }
      this.fetchSelectedShift();
    },

    async onChangeRole(e){
      const slots = await this.getShiftTimeSlotsByAreaRoleId(this.area_role_id , this.shift_id);
      this.shiftTimeSlots = slots.data.payload;
    },

    getStatus(shift){
      let status=null;
      this.selectedShifts.map((selectedShift)=>{
        if(shift.id==selectedShift.shift_id){
            status=selectedShift.status;
        }
      this.selected_shift.shift_time_slots.forEach(timeslot_id => {
        if(selectedShift.slot_id == timeslot_id.id)
        {
          this.shiftSelectedTimeId = timeslot_id.id;
        }
      })
      });
      return status;
    },

    getRoleName(shift)
    {
      this.area_role_id = this.selected_shift.area_roles[0].id;
      this.selectedShifts.map((selectedShift) => {
        if (selectedShift.shift_id == shift.id) {
          this.area_role_id = selectedShift.area_role_id;
        }else{
          this.area_role_id = shift.area_roles[0].id;
        }
        shift.shift_time_slots.map((timeslot)=>{
          if(timeslot.id == selectedShift.slot_id)
          {
            this.shiftSelectedTimeId = selectedShift.slot_id;
            this.selected_from = selectedShift.from;
            this.selected_to = selectedShift.to;
          }
        })
      });
      this.onChangeRole();
    },

    confirmOverTimeShift() {
      let message =  this.currentUser.employee.minor == 1 ? "requested to schedule" : "scheduled" ;
      Swal.fire({
        title: "Are you sure?",
        text: "You have already " + message +" a 40-hour shift for this week, if you need to work an overtime shift, your request will be forwarded to management for approval.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70C213",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, Accept",
      }).then((result) => {
        if (result.value) {
            this.submitShift();
        }
      });
    },

    checkCounts()
    {
      this.$axios
      .get("counts-stats/index", {
        params: {employee_id : this.currentUser.employee.id , shift_id: this.shift_id }
      })
      .then((response) => {
        let totalHours = response.data.payload;
        if(totalHours > 40)
        {
          this.confirmOverTimeShift();
        }else{
          this.submitShift();
        }
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      });
    },

    closeModal(){
      this.shiftSelectedTimeId = null
      this.shiftTimeSlots = []
      this.pickShiftModal=false ;
    }
  }
};
</script>

<template>
    <div>
      <div class="row">
      <div class="col-lg-12">
        <div class="dashboard__wrapper">
        
          <div >
            <div class="table-responsive mb-0">
                <b-table
                    table-class="table table-centered datatable mt-3 table-bordered f-14 roboto"
                    thead-tr-class="bg-transparent"
                    :items="shifts"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :busy="loading"
                    show-empty
                >
                 <template #table-busy>
                    <APILoader v-if="loading" :loading="loading" class="align-middle"></APILoader>
                  </template>
                <template v-slot:cell(action)="data">
                    <ul class="d-flex list-inline mb-0">
                        <li class="list-inline-item">
                           <div class="action-buttons d-flex gap-2 justify-content-between">
                              <button 
                                class="btn px-4 text-white accept-button"
                                @click="selectShifts(data.item)"
                                > Accept
                              </button>
                            </div>
                        </li>
                    </ul>
                </template>
                </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="col-sm-12 col-md-12 mt-3">
                <div class="dataTables_paginate paging_simple_numbers">
                    <ul class="pagination justify-content-end py-0 my-0 gap-2">
                    <li
                        class="paginate_button page-item previous"
                        :class="total_records.prev_page_url == null ? 'disabled' : ''"
                    >
                        <button
                        type="button"
                        :class="total_records.prev_page_url == null ? 'disabled' : ''"
                        @click="fetchShift(total_records.prev_page_url)"
                        class="btn bg-light text-dark"
                        >
                        <i class="fas fa-arrow-left"></i>
                        </button>
                    </li>

                    <li
                        class="paginate_button page-item next"
                        :class="total_records.next_page_url == null ? 'disabled' : ''"
                    >
                        <button
                        type="button"
                        :class="total_records.next_page_url == null ? 'disabled' : ''"
                        @click="fetchShift(total_records.next_page_url)"
                        aria-controls="DataTables_Table_0"
                        data-dt-idx="8"
                        tabindex="0"
                        class="btn bg-light text-dark"
                        >
                        <i class="fas fa-arrow-right"></i>
                        </button>
                    </li>
                    </ul>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

              <!-- end .border-->
              <!-- Pick Shift Modal -->
    <b-modal v-if="selected_shift!=null"
      v-model="pickShiftModal"
      title="Pick Shift"
      title-class="text-black poppins font-18"
      hide-footer
      body-class="p-3"
    >
      <div slot="modal-title poppins">Pick Shift</div>
      <form @submit.prevent="submitShift">
        <div class="row">
          <div class="col-md-8 mb-3">
              <div class="d-flex align-items-center gap-2">
                  <div class="icon-wrapper mt-3 pt-1">
                      <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                  </div>
                  <div class="form-field__wrapper w-100">
                      <b-form-group id="input-group-2" label="Shift Name" label-for="input-2">
                          <b-form-input id="input-2" v-model="selected_shift.name" readonly></b-form-input>
                      </b-form-group>
                  </div>
              </div>
          </div>
          <div class="col-md-4 mb-3" >
            <div class="d-flex align-items-center gap-2">
                <div class="icon-wrapper mt-3 pt-1">
                    <img src="@/assets/images/profile-manager/user-icon.png" width="21" alt="" />
                </div>
                <div class="form-field__wrapper w-100">
                    <label class="">Select Role <span class="primaryColor"> * </span></label>
                      <select  id="work_area" v-model="area_role_id" @change="onChangeRole()" class="form-select" :disabled="getStatus(selected_shift)=='Pending' || getStatus(selected_shift)=='Accepted'">
                        <option :value="role.id" v-for="(role,index) in selected_shift.area_roles" :key="index" >{{role.name}}</option>
                        
                    </select>
                </div>
            </div>
          </div>
          
          <div class="col-md-12 mb-3">
            <div class="d-flex gap-2">
                <div class="col-md-12" v-if="area_role_id !=null">
                    <div class="align-items-center d-flex gap-2">
                        <div class="icon-wrapper mt-3 pt-1">
                            <img src="@/assets/images/profile-manager/icon-12.png" width="21" alt="" />
                        </div>
                        
                          <div class="form-field__wrapper w-100"  v-if="getStatus(selected_shift)!='Pending' && getStatus(selected_shift)!='Accepted'">
                            <label class="text-dark">Select Shift Time <span class="primaryColor"> *</span></label>
                              <select  id="time_slot" v-model="shiftSelectedTimeId"  class="form-select">
                                <option :value="null" disabled selected hidden>Select Shift Slot</option>
                                <option v-if="shiftTimeSlots.length == 0" disabled >No Timeslot Available</option>
                                <option :value="shiftTimeBreak.id" v-for="(shiftTimeBreak, index) in shiftTimeSlots" :key="index" >{{DateWithMonthName(shiftTimeBreak.from)}}  - {{DateWithMonthName(shiftTimeBreak.to)}}</option>
                            </select>
                          </div>
                    </div>
                </div>
            </div>
          </div>
          
          <div v-if="selected_shift.shift_type=='Premium'" class="ms-4 mt-3 text-danger">
            Note: This is <span class="text-uppercase ">{{selected_shift.shift_type}}*</span> Shift. And {{selected_shift.shift_type}} Amount is <span class="">{{selected_shift.bonus}}$</span>
          </div>
        </div>
        <div class="text-end p-3 pe-0">
          <button class="btn btn-outline-pink mx-3" type="button" @click="closeModal()">Close</button>
          <SubmitButton :processing="processing" class="mx-2" text="Accept" @clicked="checkCounts" v-if="getStatus(selected_shift)!='Pending' && getStatus(selected_shift)!='Accepted'" />
        </div>
      </form>
    </b-modal>
            </div>
</template>
